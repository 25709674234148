import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Button from "../../../common/button"

import * as Styled from "./Books.styles"

const query = graphql`
  query {
    achtsamkeit: file(relativePath: { regex: "/buch.png$/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 100
        )
      }
    }
  }
`

const Books = () => {
  const { achtsamkeit } = useStaticQuery(query)

  const image: any = getImage(achtsamkeit)
  return (
    <Styled.Container>
      <div className="content">
        <div className="img">
          <GatsbyImage alt="test" image={image} objectFit="cover" />
        </div>
        <div>
          <h3>Unsere Buchempfehlung</h3>
          <p className="title">
            "Zwischen Achtsamkeit und Pragmatismus" von Christine Lehner und
            Sabine Weiher
          </p>

          <p className="text">
            Buchinhalt:
            <br />
            - Zielorientiert und konzentriert bleiben unter Stress und
            Anspannung.
            <br />
            - Verbesserung der mentalen Stärke und ihrer
            Kommunkiationskompetenzen.
            <br />- Professionalisierung des Selbstmanagements und der
            Kommunikationsstrategien.
          </p>
          <Button
            link={false}
            href="https://link.springer.com/book/10.1007/978-3-662-58915-1#aboutBook"
            type="middle"
          >
            Hier bestellen
          </Button>
        </div>
      </div>
    </Styled.Container>
  )
}

export default Books
